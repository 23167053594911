import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import StaticBanner from 'components/Banner/StaticBanner/StaticBanner';
import { Container, styles } from './banner.style';
import BannerCenterText from 'components/Banner/BannerCenterText/BannerCenterText';
import SecondaryButton from 'common/components/Button/SecondaryButton/SecondaryButton';
import BannerBG from 'common/assets/image/syncStage/banner-bg.png';
import BannerBGSmall from 'common/assets/image/syncStage/banner-bg-small.webp';
import LazyMedia from 'common/components/LazyMedia/LazyMedia';

const BannerSection = ({ handleWhitepaperDiscord }) => {
  const { title, description, buttonWrapper } = styles;
  return (
    <StaticBanner
      bg={
        <Container>
          <div className="background">
            <LazyMedia src={BannerBG} loadingSrc={BannerBGSmall} alt="SyncStage" />
            <div className="gradient-layer"></div>
          </div>
        </Container>
      }
    >
      <BannerCenterText>
        <Heading {...title}>
          <React.Fragment>
            WIN <span className="emphasize">EVERY</span> TEAMFIGHT
          </React.Fragment>
        </Heading>
        <Text {...description}>
          <React.Fragment>
            SyncStage is a patent-pending voice chat platform that allows you to communicate with your team{' '}
            <span className="emphasize">twice as fast as Discord</span>, and designed specifically for the demands of{' '}
            <span className="emphasize">
              multiplayer online competitive gaming such as League of Legends, CSGO, DOTA2 and Valorant.
            </span>
          </React.Fragment>
        </Text>
        <Box {...buttonWrapper}>
          <SecondaryButton title="DOWNLOAD WHITEPAPER" onClick={handleWhitepaperDiscord} />
        </Box>
      </BannerCenterText>
    </StaticBanner>
  );
};
export default BannerSection;
