import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import { CaseStudyDiscordWrapper, BackgroundContainer, styles } from './caseStudyDiscord.style';
import SpaceBg from 'common/assets/image/syncStage/space-bg.jpg';
import SpaceBgSmall from 'common/assets/image/syncStage/space-bg-small.webp';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';
import LazyMedia from 'common/components/LazyMedia/LazyMedia';

const CaseStudyDiscordSection = ({ handleWhitepaperDiscord }) => {
  const { title, description, buttonWrapper } = styles;
  return (
    <CaseStudyDiscordWrapper id="case_study_discord_section">
      <BackgroundContainer>
        <div className="background">
          <LazyMedia src={SpaceBg} loadingSrc={SpaceBgSmall} alt="Discord case study" />
          <div className="gradient-layer"></div>
        </div>
        <div className="content-layer">
          <Heading {...title}>
            <React.Fragment>
              <span className="emphasize">CASE STUDY</span>,<br />
              SYNCSTAGE
              <br />
              VS DISCORD
            </React.Fragment>
          </Heading>
          <Text {...description}>
            <React.Fragment>
              A comparative study across speed, quality and reliability of SyncStage vs Discord, for multiplayer online
              gaming.
            </React.Fragment>
          </Text>
          <Box {...buttonWrapper}>
            <PrimaryButton title="DOWNLOAD WHITEPAPER" onClick={handleWhitepaperDiscord} />
          </Box>
        </div>
      </BackgroundContainer>
    </CaseStudyDiscordWrapper>
  );
};

export default CaseStudyDiscordSection;
