import styled from 'styled-components';

export const Container = styled.div`
  .background {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    .gradient-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 40%);
    }
  }

  img {
    border-radius: 35px;
    width: 100%;
    object-fit: cover;
  }
`;

export const styles = {
  title: {
    fontStyle: 'normal',
    fontSize: ['50px', '60px', '70px', '80px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '40px', '40px', '40px', '40px'],
    lineHeight: '1.25',
    textAlign: 'center',
  },
  description: {
    fontSize: ['20px', '20px', '20x', '25px'],
    color: '#fff',
    lineHeight: '1.4',
    mb: '0',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '75px',
    width: '100%',
  },
};
