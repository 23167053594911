import styled from 'styled-components';
import FirstBg from 'common/assets/image/syncStage/pattern-1@2x.png';
import SecondBg from 'common/assets/image/syncStage/pattern-2@2x.png';
import ThirdBg from 'common/assets/image/syncStage/pattern-3@2x.png';

const InfoSectionWrapper = styled.section`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    radial-gradient(
      89.85% 105.84% at 44.89% 3.77%,
      #00fff0 0%,
      #00c2ff 17.71%,
      #004cdf 40.63%,
      #321497 58.85%,
      #9800cd 81.25%,
      #cd00a0 100%
    );
  z-index: 0;

  .first-bg {
    background-image: url(${FirstBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 800px;
  }

  .second-bg {
    background-image: url(${SecondBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 800px;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 800px;
  }

  .third-bg {
    background-image: url(${ThirdBg});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    top: 1600px;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
  }

  .two-col-grid {
    display: flex;
    justify-content: center;
  }

  .sticky-col {
    position: sticky;
    top: 100px;
    align-self: self-start;
    @media (max-width: 950px) {
      display: none;
    }
  }

  .scroll-col {
    width: 35%;
    margin-inline: 4rem;
    margin-block: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 15rem;

    @media (max-width: 950px) {
      width: 100%;
      row-gap: 7rem;
      margin-inline: 2rem;
    }
  }

  .button {
    padding-top: 8rem;
    padding-bottom: 5rem;
    padding-inline: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      box-sizing: border-box;
      width: fit-content;

      @media (max-width: 550px) {
        width: 100%;
      }
    }
  }
`;

export default InfoSectionWrapper;

export const styles = {
  title: {
    fontSize: ['42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    pt: ['60px', '80px', '100px', '150px', '150px'],
    pb: ['30px', '40px', '50px', '75px', '75px'],
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  titleStyle: {
    fontSize: ['26px', '36px', '40px'],
    fontWeight: 'bold',
    color: '#fff',
    mb: ['1rem', '2rem'],
    lineHeight: '1.25',
    textAlign: ['center', 'left'],
  },
  descriptionStyle: {
    fontSize: ['18px', '20px'],
    color: '#fff',
    lineHeight: '1.5',
    textAlign: ['justify', 'left'],
  },
};
