import styled from 'styled-components';
import Button from '..';
import { generalButtonStyles } from '../variants';

export const SecondaryButtonStyle = styled(Button)``;

export const styles = {
  button: {
    ...generalButtonStyles,
    colors: 'secondaryWithBg',
  },
};
