import React from 'react';
import BannerSection from 'containers/SyncStage/Banner';
import CaseStudyDiscordSection from 'containers/SyncStage/CaseStudyDiscord';
import PartnerSection from 'containers/SyncStage/Partner';
import InfoSection from 'containers/SyncStage/Info';
import { handleNewsletter, handleWhitepaperDiscord } from '../common/handlers';
import SignUpSection from '../containers/SyncStage/SignUp';
import Layout from '../components/layout';

const Gaming = () => {
  return (
    <Layout>
      <BannerSection handleWhitepaperDiscord={handleWhitepaperDiscord} />
      <PartnerSection />
      <InfoSection />
      <CaseStudyDiscordSection handleWhitepaperDiscord={handleWhitepaperDiscord} />
      <SignUpSection handleNewsletter={handleNewsletter} />
    </Layout>
  );
};
export default Gaming;
