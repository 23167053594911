import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import InfoSectionWrapper, { styles } from './info.style';
import Text from 'common/components/Text';
import IconsGrid from 'common/components/IconsGrid';
import SpeedImage from 'common/assets/image/syncStage/speed.svg';
import ScaleImage from 'common/assets/image/syncStage/scale.svg';
import SecurityImage from 'common/assets/image/syncStage/security.svg';
import PlayersImage from 'common/assets/image/syncStage/players.svg';
import SecondaryButton from 'common/components/Button/SecondaryButton/SecondaryButton';

const InfoSection = () => {
  const { title, titleStyle, descriptionStyle } = styles;

  const leftItemRef = useRef(null);
  const itemsRef = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [itemsSelection, setItemsSelection] = useState([0, 0, 0, 0]);
  const handleScroll = useCallback(() => {
    const leftRect = leftItemRef?.current?.getBoundingClientRect();
    if (!leftRect) {
      return;
    }
    let currentIndex = 0;
    for (let i = 0; i < itemsRef.length; i++) {
      const rect = itemsRef[i].current.getBoundingClientRect();
      if (rect.top >= leftRect.top && rect.top < leftRect.bottom) {
        currentIndex = i;
        break;
      }
    }
    const newItemsSelection = [0, 0, 0, 0];
    if (currentIndex > 0) {
      newItemsSelection[currentIndex - 1] = 0; //(currentTop - leftRect.top) / leftRect.height;
    }
    newItemsSelection[currentIndex] = 1; //(leftRect.bottom - currentTop) / leftRect.height;
    setItemsSelection(newItemsSelection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <InfoSectionWrapper id="info_section">
      <Box>
        <Heading {...title} content="Key benefits" />
      </Box>
      <Container fullWidth noGutter className="two-col-grid">
        <div className="first-bg"></div>
        <div className="second-bg"></div>
        <div className="third-bg"></div>

        <div className="sticky-col" ref={leftItemRef}>
          <IconsGrid
            items={[
              {
                icon: SpeedImage,
                alt: 'Speed',
                title: 'BUILT FOR',
                subtitle: 'SPEED',
                borderOpacity: itemsSelection[0],
              },
              {
                icon: SecurityImage,
                alt: 'Security',
                title: 'BUILT FOR',
                subtitle: 'SECURITY',
                borderOpacity: itemsSelection[1],
              },
              {
                icon: ScaleImage,
                alt: 'Scale',
                title: 'BUILT FOR',
                subtitle: 'SCALE',
                borderOpacity: itemsSelection[2],
              },
              {
                icon: PlayersImage,
                alt: 'Players',
                title: 'BUILT FOR',
                subtitle: 'PLAYERS',
                borderOpacity: itemsSelection[3],
              },
            ]}
          />
        </div>

        <div className="scroll-col">
          <div className="info-item" ref={itemsRef[0]}>
            <Heading {...titleStyle}>
              <React.Fragment>
                BUILT FOR <span className="emphasize">SPEED</span>
                <br />
                (5x to 7x FASTER)
              </React.Fragment>
            </Heading>
            <Text
              {...descriptionStyle}
              content="When milliseconds matter, SyncStage has been designed to be the quickest connection between teams on the planet. We are 5x to 7x faster than other voice/audio over IP communications platforms, allowing you to communicate faster with your team, deliver more actions per minute than your competitors when in a Teamfight. "
            />
          </div>
          <div className="info-item" ref={itemsRef[1]}>
            <Heading {...titleStyle}>
              <React.Fragment>
                BUILT FOR <span className="emphasize">SECURITY</span>
                <br />
                (99.9% Guaranteed Uptime + 24 x 7 Support)
              </React.Fragment>
            </Heading>
            <Text
              {...descriptionStyle}
              content="Designed for the demands of telecom networks, we have built SyncStage to be enterprise ready."
            />
          </div>
          <div className="info-item" ref={itemsRef[2]}>
            <Heading {...titleStyle}>
              <React.Fragment>
                BUILT FOR <span className="emphasize">SCALE</span>
              </React.Fragment>
            </Heading>
            <Text
              {...descriptionStyle}
              content="Engineered to handle seamlessly multiple concurrent sessions meeting demands of the gaming industry."
            />
          </div>
          <div className="info-item last" ref={itemsRef[3]}>
            <Heading {...titleStyle}>
              <React.Fragment>
                BUILT FOR THE <span className="emphasize">PLAYERS</span>,<br />
                BY THE PLAYERS
              </React.Fragment>
            </Heading>
            <Text
              {...descriptionStyle}
              content="Designed in collaboration with the world’s best players at SK Gaming, SyncStage acts a learning companion for the player’s competing at home, to get you ready for the world stage."
            />
          </div>
        </div>
      </Container>
      <div className="button">
        <a href={process.env.REQUEST_DEMO_URL}>
          <SecondaryButton title="REQUEST A DEMO" />
        </a>
      </div>
    </InfoSectionWrapper>
  );
};

export default InfoSection;
